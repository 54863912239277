const Positions = {
  data: {
    items: [
      {
        title: `Embedded Systems Engineer`,
        posDescNum: `HR-023-04`,
        department: `Engineering`,
        reportsTo: `Engineering Manager, Project Manager`,
        jobDescription: `If you are an Embedded Systems Engineer with experience, please read on!\n\nWe are a rapidly growing, high speed engineering services company with product development in the medical, mil/aero and high-end industrial spaces. The work is exciting and challenging every day.  Our customers range from fortune 50 companies to startups and across many projects. You can work on satellites, trains, planes, IoT devices to life saving medical devices all in the same week! We are a small team accomplishing great things and are looking for experienced engineers to join our tight knit & growing team.`,
        jobDuties: [
          `Technically leads and/or executes difficult, complex electrical and software designs.`,
          `Interfaces with external customers or vendors regarding technical and design issues.`,
          `Interfaces with internal customers regarding requirements analysis, technical development, budget and schedule.`,
          `Contributes to the design, development, and testing of electrical equipment, components, or systems. Requires specialized depth and/or breadth of expertise in electrical design and software engineering.`,
          `Component selection, schematic development, circuit board layout and systems bring up and debug through product finalization.`,
          `Design Validation Test, Factory Test development and Test integration.`,
          `Troubleshoots a wide range of complex, difficult hardware and firmware problems.  Performs firmware tests on code and enhancements. Develops firmware documentation.`,
          { li: `Defines software specifications, and designs software products, such as:`,
            items: [`C/C++`, `Algorithms`, `Data Structures`, `Drivers`, `Kernals`, `Artificial Intelligence`, `Motion`] },
          `Contributes to technical review boards for assigned programs.`,
          `Develops code for firmware projects, and design and implement bug fixes.`,
          `Compiles data for regularly scheduled or special reports, analysis and statements.`,
          `Demonstrates depth and/or breadth of expertise in own specialized discipline or field `,
          `Interprets internal/external business challenges and recommends best practices to improve products, processes or services`,
          `May lead functional teams or projects with moderate resource requirements, risk, and/or complexity `,
          `Leads others to solve complex problems; uses sophisticated analytical thought to exercise judgment and identify innovative solutions`,
          `Communicates difficult concepts and negotiates with others to adopt a different point of view`
        ],
        jobRequirements: `B.S., M.S. or Ph. D in Computer Science, Computer Engineering, Electrical Engineering, or closely related field AND Strong C/C++ programming skills.Good computer science fundamentals (e.g., data structures, concurrency, and algorithm design), Good understanding of operating system internals (e.g., kernel or driver knowledge), Good problem solving and debugging skills, Good communication skills. So, if you are an Embedded Systems Engineer with experience, please apply today!`,
        path: `/embedded-systems-engineer-lvl4`
      }
    ]
  },
  accessors: {},
  mutators: {}
}

const Careers = {
  data: {
    title: 'Careers'
  }
}

const CareersStore = {
  data: {
    Positions,
    Careers
  }
}

export default CareersStore
