<template>
  <div id="careers-page">
    <HeroImage
      :imgSrc="img"
      :title="pageTitle"
      :position="pos"
      class="hidden-md-and-down"
    />
      <v-container grid-list-md>
        <v-layout
          align-start
          justify-center
          column
        >
          <v-flex xs12 sm6 v-for="(p,i) in positions" :key="i">
            <v-card dense>
              <v-container dense>
                <v-layout column fill-height>
                  <v-flex xs12>
                    <v-card-title id="position"><h1>{{ p.title }}</h1></v-card-title>
                    <v-card-text id="job-details">
                      <v-layout column fill-height>
                        <span><strong>POSITION DESCRIPTION NUMBER: </strong>{{ p.posDescNum }}</span>
                        <span><strong>DEPARTMENT: </strong>{{ p.department }}</span>
                        <span><strong>REPORTS TO: </strong>{{ p.reportsTo }}</span>
                      </v-layout>
                    </v-card-text>
                    <hr/>
                    <v-card-text id="job-purpose">
                      <br/>
                      <h2>Job Description</h2>
                      <p>{{ p.jobDescription }}</p>
                      <h2>Job Duties</h2>
                      <ol>
                          <li v-for="(duty, j) in p.jobDuties" :key="j">
                            <template v-if="duty.li">
                              {{ duty.li }}
                              <ul>
                                <li v-for="(item, k) in duty.items" :key="k">{{ item }}</li>
                              </ul>
                            </template>
                            <template v-else>{{ duty }}</template>
                          </li>
                      </ol>
                      <br/>
                      <h2>Education and Experience</h2>
                      <p>{{ p.jobRequirements }}</p>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 style="width: 100%;">
            <v-card dense>
              <v-container dense>
                <v-layout column fill-height>
                  <v-flex xs12>
                    <v-card-title><h1>How to Apply</h1></v-card-title>
                    <v-card-text id="career-contact-info">
                      <v-layout column fill-height>
                        <p>Please email your resume to <strong>JamieHale@darceo.com</strong> with the subject line as the <strong>Position Description Number</strong> of the position you are applying to.</p>
                        <br/>
                        <b>Darceo is proud to be an Equal Opportunity Employer</b>
                        <i>Applicants must be a U.S. citizen. All qualified applicants will receive consideration for employment without regard to race, color, religion, sex, national origin, disability, protected veteran status, or any other characteristic protected by law.</i>
                        <i><b>Your Right to Work</b> - In compliance with federal law, all persons hired will be required to verify identity and eligibility to work in the United States and to complete the required employment eligibility verification document form upon hire.</i>
                      </v-layout>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </div>
</template>

<script>
import CareersStore from '@/stores/CareersStore.js'
import HeroImage from '../layout/HeroImage.vue'
export default {
  data: () => ({
    dialog: false,
    pageTitle: CareersStore.data.Careers.data.title,
    positions: CareersStore.data.Positions.data.items,
    img: 'https://images.unsplash.com/photo-1489486501123-5c1af10d0be6?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    pos: 'bottom 30% center'
  }),
  components: {
    HeroImage
  }
}
</script>

<style scoped>
</style>
