<template>
  <v-layout>
    <v-flex xs12>
      <v-card :flat="flat">
        <v-img
          :height="height"
          :src="imgSrc"        
          :position="imgPosition"
          gradient="to right, rgba(0,0,0,.45), rgba(0,0,0,0)"
        >
          <v-container fill-height fluid>
            <v-layout align-center>
              <v-flex xs12>
                <span class="headline">
                  <h1 id="page-title" :class="{ 'display-3': $vuetify.breakpoint.xs}">{{ title }}</h1>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    defaultPos: 'bottom 25% center'
  }),
  props: {
    imgSrc: String,
    title: String,
    position: String,
    flat: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  computed: {
    imgPosition: function () {
      if (this.position != this.defaultPos) {
        return this.position
      }
      else {
        return this.defaultPos
      }
    }
  }
}
</script>

<style scoped>
h1 {
  color:white;
  font-size: 60pt;
}

.subheading {
  color: white;
  font-size: 5em;
}
</style>
