<template>
  <div id="careers">
    <CareersPage/>
  </div>
</template>

<script>
import CareersPage from '../components/careers/CareersPage.vue'
export default {
  data: () => ({}),
  components: {
    CareersPage
  }
}
</script>
